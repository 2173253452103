import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    listData:[],   //首页课程的数据
    dialogFormVisible:false,
    myObject:{},//审批详情数据
  },
  getters: {
  },
  mutations: {
    saveListData(state,newlistData){
      state.listData = newlistData
    },
    changeDialog(state,dialog){
      state.dialogFormVisible = dialog
    },
    SET_MY_OBJECT(state, payload) {
      state.myObject = payload;
    }
  },
  actions: {
    updateMyObject({ commit }, payload) {
      commit('SET_MY_OBJECT', payload);
    }
  },
  getters: {
    getMyObject: state => state.myObject
  },
  modules: {
  }
})
