
<template>
  <div class="jyb-focus">
    <div class="Notice" v-if="urlsData.length>0">
      <div class="pa17 Notice_list">
        <div class="item fex" v-for="(item,index) in urlsData" :key="index">
          <div  class="title">
            <a :href="item.url" target="_blank">
              <div class="titlecolor">{{ item.title }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <swiper class="mySwiper" :options="swiperOptions" v-if="images.length">
      <swiper-slide v-for="(item, index) in images" :key="index">
        <img class="mySwiperwrapperimg" :src="'https://clo.litaifs.com/'+item.image" />
      </swiper-slide>
    </swiper>
  </div>
</template>
     <script>
import { bannerList, urls_list } from "@/api/home.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: { swiper, swiperSlide },

  data() {
    return {
      urlsData: [],
      fileHost: "https://clo.litaifs.com/",
      images: [],
      swiperOptions: {
        // 基本配置
        direction: "horizontal", // 滑动方向，可以是'horizontal'或'vertical'
        loop: true, // 是否循环
        autoplay: {
          delay: 8000, // 自动播放间隔
          disableOnInteraction: false // 用户操作后是否停止自动播放
        },
        // 分页器配置
        pagination: {
          clickable: true // 分页器是否可点击
        },
        // 导航按钮配置
        navigation: {
          nextEl: ".swiper-button-next", // 下一项元素
          prevEl: ".swiper-button-prev" // 上一项元素
        },
        // 滚动条配置
        scrollbar: {
          el: ".swiper-scrollbar" // 滚动条元素
        }
      }
    };
  },
  created() {
    this.banner();
    this.linkList();
  },
  computed: {},

  methods: {
    //查询banner列表
    banner() {
      bannerList().then(res => {
        if (res.code == 200) {
          this.images = res.data;
        }
      });
    },
    getstyle(img) {
      return {
        background: "url(" + "https://clo.litaifs.com/" + img + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: " no-repeat"
      };
    },
    // 查看动态
    ViewNews(item) {},
    // 查询链接
    linkList() {
      urls_list().then(res => {
        if (res.code == 200) {
          this.urlsData = res.data.data;
        }
      });
    }
  }
};
</script>
     <style lang="scss">
.pa17 {
  padding: 17px;
  box-sizing: border-box;
}
.jyb-focus {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .mySwiper {
    width: 100%;
    min-width: 1300px;
    height: 100%;
    .mySwiperwrapperimg {
      width: 100%;
      min-width: 1300px;
      height: 100%;
      margin: auto;
      background-size: 100%;
    }
  }
  .Notice {
    width: 388px;
    height: 415px;
    border-top-left-radius: 30px;
    background-image: url('../../assets/5.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: -19px;
    .Notice_header {
      height: 55px;
      line-height: 55px;
      text-align: center;
      border-top-left-radius: 30px;
      background: #ffffff;
      color: rgba(212, 48, 48, 1);
      font-size: 20px;
      font-weight: 500;
      vertical-align: middle;
      img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .Notice_list {
      padding: 10px 20px 20px 20px;
      margin-top: 10px;
      margin-top:calc(100% - 225px);
      box-sizing: border-box;
      height: calc(100% - 160px);
      overflow-y: auto;
      box-sizing: border-box;
      .item {
        width: 85%;
        height: 35px;
        background: #ffffff;
        border-radius: 5px;
        padding: 5px 0px 5px 10px;
        box-sizing: border-box;
        color: rgba(51, 51, 51, 1);
        cursor: pointer;
        margin: auto;
        margin-bottom: 12px;

        .yuan {
          width: 3px;
          height: 3px;
          background: rgba(51, 51, 51, 1);
          border-radius: 50%;
          margin: 0px 5px;
        }
        .title{
          width: 99%;
        }
        .titlecolor {
          width: 100%;
          width: 100%;
          color: #333333;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.Notice_list::-webkit-scrollbar {
  display: none;
}
</style>