import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/views/Layout/index.vue"
import Home from "@/views/home/index.vue"
// import ReturnClothes from '@/views/yangyiguihuan/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children:[
      // 首页
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {requiresAuth: true  },
      },
      // 学院课堂 
      {
          path: '/college',
          name: 'college',
          meta: {headerColor: 'rgba(219, 0, 0, 1)' },
          component: () => import(/* webpackChunkName: "about" */ '../views/xueyuanketang'),  
      },
      // 合作学校/详情
      {
        path: '/cooperate',
        name: 'cooperate',
        meta: {headerColor: 'rgba(219, 0, 0, 1)' },
        component: () => import(/* webpackChunkName: "about" */ '../views/heizuo'),  
    },
    {
       path: '/cooperate/details',
       name: 'cooperate',
       meta: {headerColor: 'rgba(219, 0, 0, 1)' },
       component: () => import(/* webpackChunkName: "about" */ '../views/heizuo/details.vue'),  
    },
      // 样衣图库/详情
      {
          path: '/SampleClothes',
          name: 'SampleClothes',
          meta: {headerColor: 'rgba(219, 0, 0, 1)' },
          component: () => import(/* webpackChunkName: "about" */ '../views/yangyituku'),  
      },
      {
         path: '/SampleClothes/details',
         name: 'SampleClothes',
         meta: {headerColor: 'rgba(219, 0, 0, 1)' },
         component: () => import(/* webpackChunkName: "about" */ '../views/yangyituku/details.vue'),  
      },
      // 其他分类的详情
        {
            path: '/Course/details',
            name: 'Course/details',
            meta: {headerColor: 'rgba(219, 0, 0, 1)' },
            component: () => import(/* webpackChunkName: "about" */ '../views/Course'),    
            
          },
          {
            path: '/iframe',
            name: 'iframe',
            meta: {headerColor: 'rgba(219, 0, 0, 1)' },
            component: () => import(/* webpackChunkName: "about" */ '../views/Course/iframe.vue'),    
           },
          // 合作案例详情和样衣图库详情
          {
            path: '/otherDetails',
            name: 'otherDetails',
            meta: {headerColor: 'rgba(219, 0, 0, 1)' },
            component: () => import(/* webpackChunkName: "about" */ '../views/Course/otherdetails.vue'),    
           },
          //  样衣归还
          {
            path: '/Return-clothes',
            name: 'Return-clothes',
            meta: {headerColor: 'rgba(219, 0, 0, 1)' },
            component: () => import(/* webpackChunkName: "about" */ '../views/yangyiguihuan/index.vue'),    
         },
         {
          path: '/Return-modify',
          name: 'Return-modify',
          meta: {headerColor: 'rgba(219, 0, 0, 1)' },
          component: () => import(/* webpackChunkName: "about" */ '../views/yangyiguihuan/modify.vue'),    
       },
           // 个人中心  审批页面
           {
            path: '/Toexamine',
            name: 'Toexamine',
            meta: {headerColor: 'rgba(219, 0, 0, 1)' },
            component: () => import(/* webpackChunkName: "about" */ '../views/gerenzhongxing/shengpi.vue'),    
           },
          //  审批页面详情页
          {
            path: '/Toexamine_details',
            name: 'Toexamine_details',
            meta: {headerColor: 'rgba(219, 0, 0, 1)' },
            component: () => import(/* webpackChunkName: "about" */ '../views/gerenzhongxing/details.vue'),    
           },
            // 百问百答列表页
            {
              path: '/hundred',
              name: 'hundred',
              meta: {headerColor: 'rgba(219, 0, 0, 1)' },
              component: () => import(/* webpackChunkName: "about" */ '../views/baiwenbaida/index.vue'),    
             },
            //  百问百答列详情页
            {
              path: '/hundred_answers',
              name: 'hundred_answers',
              meta: {headerColor: 'rgba(219, 0, 0, 1)' },
              component: () => import(/* webpackChunkName: "about" */ '../views/baiwenbaida/details.vue'),    
             },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
